import { template as template_247eadbe359045ad8223d0f05fbf14c2 } from "@ember/template-compiler";
const FKText = template_247eadbe359045ad8223d0f05fbf14c2(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
