import { template as template_e387c959258047af9d2a44c2767d6b6c } from "@ember/template-compiler";
const FKLabel = template_e387c959258047af9d2a44c2767d6b6c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
